<template>
  <div class="settings-edit__cta">
    <LxpButton block @click="onProfileUpdate(section)" :disabled="disabled">
      <img
        src="@/assets/images/settings/icons/loader.svg"
        class="ct__loader"
        v-if="isLoading"
      />
      <span v-else> {{ $t("general.save") }}</span>
    </LxpButton>
  </div>
</template>
<script>
import SettingsMixin from "../mixin";
import { LxpButton } from "didactica";

export default {
  mixins: [SettingsMixin],
  components: { LxpButton },
  props: {
    section: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLoading() {
      return (
        this.section.id === this.getSettingsCurrentSection.id &&
        this.getCurrentStatus === "LOADING"
      );
    }
  }
};
</script>
<style lang="scss">
.settings-edit__cta {
  margin-bottom: 7px;
  .ct__loader {
    animation: spin 1000ms infinite linear;
    @include spin-animation-mixin(0deg, 360deg);
  }
  .btn-primary {
    background-color: $brand-primary-400 !important;
  }
}
</style>
