<template>
  <div :class="['settings-card', { edit: section.isEdit }]">
    <div class="settings-card__heading">
      <slot name="header-text"></slot>
      <div class="sc-heading__actions">
        <span
          @click="onOpen(section)"
          v-if="!section.isEdit"
          class="sc-heading__actions--edit"
          >Edit</span
        >
        <slot name="header-save__btn" v-else></slot>
      </div>
    </div>
    <div class="sc-content">
      <div class="sc-content__content" v-if="!section.isEdit">
        <slot name="card-content"></slot>
      </div>
      <div class="sc-content__edit" v-else>
        <slot name="card-edit-content"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import SettingsMixin from "../mixin";
export default {
  props: ["section"],
  mixins: [SettingsMixin]
};
</script>
<style lang="scss" scoped>
.settings-card {
  background: $brand-neutral-0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 16px;
  .sc-heading__actions {
    cursor: pointer;
    .sc-heading__actions--edit {
      @include label-large;
      font-weight: 500;
      color: $brand-primary-400;
    }
  }
  &:hover {
    box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.1);
  }
  .settings-card__heading {
    @include horizontal-space-between;
    height: 32px;
    margin-bottom: 16px;
    h5 {
      @include settings-header;
      margin-bottom: 0;
    }
  }
  .sc-content {
    height: calc(100% - 40px);
    label {
      @include label-medium;
      color: $brand-neutral-300;
    }
    .sc-content__edit {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .sc-content__edit--wrapper {
        height: calc(100% - 50px);
      }
    }
  }
  &.edit {
    height: auto;
  }
}
[dir="rtl"] {
  .settings-card {
    .sc-content {
      .sc-content__content {
        text-align: right;
      }
    }
  }
}
</style>
<style lang="scss">
.settings-card {
  .sc-content__edit {
    .form-floating {
      label {
        color: $brand-primary-700;
      }
    }
    .date-edit {
      .form-control:disabled {
        background: $brand-primary-75;
        color: $brand-neutral-700;
        ~ label {
          font-weight: 400;
          color: $brand-primary-700 !important;
        }
      }
      .lxp-datepicker {
        position: absolute;
        top: 0px;
        right: 16px;
        .datepicker__div {
          background: none;
          border: 0px !important;
          &:hover {
            background: none !important;
          }
          .btn {
            background: none;
            border: 0px;
            &:focus {
              border: 0px !important;
            }
          }
        }
      }
    }
  }
}
</style>
