<template>
  <div class="md-general">
    <settings-card :section="getASection('general')">
      <template v-slot:header-text>
        <h5>{{ $t("onboarding.steps.general") }}</h5>
      </template>
      <template v-slot:header-save__btn>
        <footer-button
          :section="getASection('general')"
          :disabled="inValid"
        ></footer-button>
      </template>
      <template v-slot:card-content>
        <div class="md-general__card-content">
          <b-row>
            <b-col cols="6" class="mb-3">
              <label>{{ $t("my_profile.details.email") }}</label>
              <p>{{ allProfile.email ? allProfile.email : "-" }}</p>
            </b-col>
            <b-col cols="6" class="mb-3">
              <label>{{ $t("settings.my_details.general.public_name") }}</label>
              <p>
                {{ allProfile.display_name ? allProfile.display_name : "-" }}
              </p>
            </b-col>
            <b-col cols="6" class="mb-3">
              <label>{{ $t("my_profile.details.first_name") }}</label>
              <p>{{ allProfile.given_name }}</p>
            </b-col>
            <b-col cols="6" class="mb-3">
              <label>{{ $t("my_profile.details.last_name") }}</label>
              <p>{{ allProfile.family_name }}</p>
            </b-col>
            <b-col cols="6" class="mb-3">
              <label>{{
                $t("settings.my_details.general.date_of_birth")
              }}</label>
              <p>{{ allProfile.date_of_birth | formatDate("DD/MM/YYYY") }}</p>
            </b-col>
            <b-col cols="6" class="mb-3">
              <label>{{ $t("my_profile.details.gender") }}</label>
              <p>
                {{
                  allProfile.gender
                    ? allProfile.gender.id === 3
                      ? allProfile.udf_gender
                      : allProfile.gender.name
                    : "-"
                }}
              </p>
            </b-col>
            <b-col cols="6" class="mb-3">
              <label>{{ $t("my_profile.details.country.title") }}</label>
              <p>{{ allProfile.country ? allProfile.country.name : "-" }}</p>
            </b-col>
            <b-col cols="6" class="mb-3">
              <label>{{ $t("onboarding.location.city.label") }}</label>
              <p>{{ allProfile.city ? allProfile.city : "-" }}</p>
            </b-col>
          </b-row>
        </div>
      </template>
      <template v-slot:card-edit-content>
        <div class="md-general--edit sc-content__edit--wrapper">
          <div class="pr-edit__div">
            <b-row class="md-general__form">
              <b-col cols="6" class="mb-3">
                <CInput
                  id="email"
                  :label="$t('my_profile.details.email') + '*'"
                  :placeholder="$t('my_profile.details.email')"
                  :required="true"
                  :value="form.email"
                  disabled
                />
              </b-col>

              <b-col cols="6" class="mb-3">
                <CInput
                  id="display_name"
                  :label="$t('settings.my_details.general.public_name')"
                  :class="{ 'field-error': errors.display_name }"
                  :placeholder="
                    $t('settings.my_details.general.display_name.title')
                  "
                  @input="e => validateInput('display_name', e)"
                  :value="form.display_name"
                />
                <span class="error mb-2" v-if="errors.display_name">{{
                  errors.display_name
                }}</span>
              </b-col>
              <b-col cols="6" class="mb-3">
                <CInput
                  id="first_name"
                  :label="$t('my_profile.details.first_name') + '*'"
                  :class="{ 'field-error': errors.given_name }"
                  :placeholder="$t('my_profile.details.first_name')"
                  :required="true"
                  @input="e => validateInput('given_name', e)"
                  :value="form.given_name"
                />
                <span class="error mb-2" v-if="errors.given_name">{{
                  errors.given_name
                }}</span>
              </b-col>
              <b-col cols="6" class="mb-3">
                <CInput
                  id="last_name"
                  :label="$t('my_profile.details.last_name') + '*'"
                  :class="{ 'field-error': errors.family_name }"
                  :placeholder="$t('my_profile.details.last_name')"
                  :required="true"
                  @input="e => validateInput('family_name', e)"
                  :value="form.family_name"
                />
                <span class="error mb-2" v-if="errors.family_name">{{
                  errors.family_name
                }}</span>
              </b-col>
              <b-col cols="6" class="mb-3 date-edit">
                <CInput
                  :label="$t('settings.my_details.general.date_of_birth') + '*'"
                  :placeholder="$t('settings.my_details.general.date_of_birth')"
                  :required="true"
                  :value="form.date_of_birth | formatDate('DD/MM/YYYY')"
                  disabled
                />
                <LxpDatepicker
                  :initialDate="selectedDate"
                  @date-Selected="onDateSelected"
                  :min="minDate"
                  :max="maxDate"
                />
              </b-col>
              <b-col cols="6" class="mb-3">
                <Select
                  :label="$t('my_profile.details.gender') + '*'"
                  :placeholder="$t('my_profile.details.gender')"
                  :required="true"
                  :dropdownArr="allConstants.genders"
                  :value="form.gender ? form.gender.name : null"
                  @click="onSelect($event, 'gender', form)"
                />
              </b-col>
              <b-col
                cols="6"
                class="mb-3"
                v-if="form.gender && form.gender.id === 3"
              >
              </b-col>
              <b-col
                cols="6"
                class="mb-3"
                v-if="form.gender && form.gender.id === 3"
              >
                <CInput
                  id="gender"
                  :label="$t('my_profile.gender.other.please_specify')"
                  :placeholder="$t('settings.my_profile.gender')"
                  :required="true"
                  :value="form.udf_gender"
                  @input="e => (form.udf_gender = e)"
                  :maxlength="64"
                />
              </b-col>
              <b-col cols="6" class="mb-3">
                <Select
                  :label="$t('my_profile.details.country.title') + '*'"
                  :placeholder="$t('my_profile.details.country.title')"
                  :required="true"
                  :dropdownArr="allConstants.countries"
                  :value="form.country ? form.country.name : null"
                  @click="onSelect($event, 'country', form)"
                />
              </b-col>
              <b-col cols="6" class="mb-3">
                <CInput
                  :label="$t('onboarding.location.city.label') + '*'"
                  :placeholder="$t('onboarding.location.city.label')"
                  :required="true"
                  @input="e => (form.city = e)"
                  :value="form.city"
                />
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
    </settings-card>
  </div>
</template>
<script>
import { LxpDatepicker } from "didactica";
import moment from "moment";

import SettingsMixin from "../mixin";

import SettingsCard from "../Cards/SettingsCard.vue";
import FooterButton from "../Shared/FooterButton.vue";

// import Cropper from "cropperjs";

export default {
  mixins: [SettingsMixin],
  components: { SettingsCard, FooterButton, LxpDatepicker },
  data() {
    return {
      showGenderText: false,
      given_name: "",
      errorMessage: "",
      showErrorModal: false,
      minDate: moment("1920-01-01").toDate(),
      maxDate: moment()
        .subtract(1, "year")
        .endOf("year")
        .toDate(),
      errors: {
        display_name: "",
        given_name: "",
        family_name: ""
      }
    };
  },
  computed: {
    inValid() {
      return Object.values(this.errors).some(error => error !== "");
    }
  },
  methods: {
    validateInput(field, event) {
      this.form[field] = event;
      if (this.form[field].length > 64) {
        this.errors[field] = "Please make text shorter";
      } else {
        this.errors[field] = "";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.md-general {
  .settings-card.edit {
    height: auto;
  }
  .md-general__form {
    .md-general__form--profile {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 0 16px 16px;
      .md-form--name {
        padding-left: 16px;
        width: 100%;
      }
    }
    .md-form__helper-text {
      color: $brand-neutral-400;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: inherit;
      margin-top: 4px;
    }
    .field-error {
      border-color: $brand-error-400;
    }
    .error {
      @include label-medium;
      color: $brand-error-400;
      margin: 4px;
    }
  }
}
</style>
